import { useTranslation } from 'react-i18next';
import React from 'react';

import FoundBlock from '../components/FoundBlock';
import FoundForm from '../components/FoundForm';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const FoundPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t('downloadTaggi')} />
      <section className="container contrast flex-col lg:py-16 px-4 sm:py-8">
        <div className="text-center my-8">
          <h3>
            {t('didYouFoundShort')}
          </h3>
        </div>
        <div className="flex">
          <div>
            <div className="self-center">
              <FoundBlock />
            </div>
          </div>
          <div
            className="my-8 lg:my-0 lg:mx-8 flex items-center justify-center"
          >
            <div className="h-full w-px bg-black" />
            <div className="w-full h-px bg-black" />
            <div className="absolute p-4 leading-3 bg-gray-200 text-lg">
              <strong>
                {t('or')}
              </strong>
            </div>
          </div>
          <div className="flex">
            <div className="self-center">
              <FoundForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FoundPage;
